import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import ProjectPage from 'components/ProjectPage'

const Project = ({ data }) => (
  <>
    <Helmet title='Sukiyaki | François Deguire' />
    <ProjectPage
      name='Sukiyaki'
      description={`
        The goal of this project was to create a branding and media for a brand
        in a graphic design class. This branding was created for Sukiyaki, a fictive
        Asian cuisine restaurant that offers a contemporary casual dining experience
        in Montréal. The logo system was inspired by the hot bowl and egg yolk that
        make up the traditional sukiyaki meal.
      `}
      meta={`
        For Université de Montréal<br>
        In 2019
      `}
      images={Object.values(data).map(v => v.childImageSharp.fluid)}
    />
  </>
)

export const query = graphql`
  query {
    sukiyaki1: file(relativePath: { eq: "sukiyaki/1.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sukiyaki2: file(relativePath: { eq: "sukiyaki/2.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sukiyaki3: file(relativePath: { eq: "sukiyaki/3.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sukiyaki4: file(relativePath: { eq: "sukiyaki/4.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sukiyaki5: file(relativePath: { eq: "sukiyaki/5.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sukiyaki6: file(relativePath: { eq: "sukiyaki/6.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Project
